<template>
  <div>

    <div class="row">

      <div class="col-md-8">
        <b-card-code title="Detail Auction">
          <div class="table-responsive">
            <table class="table table-hover">
              <tbody>
                <tr>
                  <td class="pl-0" width="25%">UUID</td>
                  <td><small>{{ auction.uuid }}</small></td>
                </tr>
                <tr>
                  <td class="pl-0">Commodity</td>
                  <td>{{ typeof auction.commodity !== undefined ? auction.commodity.name : '' }}</td>
                </tr>
                <tr>
                  <td class="pl-0">Depository</td>
                  <td>{{ typeof auction.warehouse !== undefined ? auction.warehouse.name : '' }}</td>
                </tr>
                <tr>
                  <td class="pl-0">Status</td>
                  <td style="text-transform: capitalize;">{{ auction.status }}</td>
                </tr>
                <tr>
                  <td class="pl-0">Price</td>
                  <td>
                    Open: <strong>{{ auction.sob_formatted }}</strong><br>
                    Last: <strong>{{ auction.last_price_formatted }}</strong><br>
                    Change: <strong>{{ auction.price_change_percent.toFixed(2) }}%</strong><br>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">Settlement</td>
                  <td>
                    <span v-if="auction.settlement_date == null">
                      <strong>T+{{ auction.settlement_days }}</strong> on <strong>{{ auction.is_settlement_only_weekday ? 'Working Days' : 'Calendar Days' }}</strong>
                    </span>
                    <span v-else>
                      {{ auction.settlement_date }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">Automatic Settlement</td>
                  <td>
                    <span v-if="auction.is_automatic_settlement">
                      <feather-icon
                        size="21"
                        icon="CheckCircleIcon"
                      />
                    </span>
                    <span v-else>
                      <feather-icon
                        size="21"
                        icon="XCircleIcon"
                      />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    Trading Algorithm
                  </td>
                  <td>
                    <strong class="text-capitalize">{{ auction.trading_algorithm.replaceAll('_', ' ') }}</strong>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    Restricted to Trade
                  </td>
                  <td>
                    <strong>{{ auction.restricted_to_trade ? 'Yes' : 'No' }}</strong>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    Price Movement
                  </td>
                  <td>
                    <strong>{{ auction.price_movement }}</strong>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    Limit Price
                  </td>
                  <td>
                    <span v-if="auction.limit_price_increase_type == 'percent'">Increase</span><span v-else>High</span>: <strong>{{ auction.limit_price_increase }} <span v-if="auction.limit_price_increase_type == 'percent'">%</span></strong><br>
                    <span v-if="auction.limit_price_decrease_type == 'percent'">Decrease</span><span v-else>Low</span>: <strong>{{ auction.limit_price_decrese }} <span v-if="auction.limit_price_decrease_type == 'percent'">%</span></strong>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    Minimum Transaction
                  </td>
                  <td>
                    <strong>{{ auction.minimum_lot_transaction }} Lot</strong>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    Lot Tolerance
                  </td>
                  <td>
                    <strong>{{ auction.lot_tolerance }}%</strong>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    Buyer Fee
                  </td>
                  <td>
                    <strong>{{ auction.buyer_fee_formatted }} {{ auction.buyer_fee_payment_on.replace('_', ' ') }}</strong>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    Seller Fee
                  </td>
                  <td>
                    <strong>{{ auction.seller_fee_formatted }} {{ auction.seller_fee_payment_on.replace('_', ' ') }}</strong>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    VAT/PPN
                  </td>
                  <td>
                    <strong>{{ auction.vat_amount }}%</strong>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    Margin for Buy
                  </td>
                  <td>
                    Margin for Buy: <strong>{{ auction.margin_minimum_for_buy_formatted }}</strong><br>
                    If Settlement Failed: <br>
                    Buyer Penalty: <strong>{{ auction.buyer_penalty_formatted }}</strong><br>
                    Seller Compensation: <strong>{{ auction.seller_compensation_formatted }}</strong><br>
                  </td>
                </tr>
                <tr>
                    <td class="pl-0">
                      Margin for Sell
                    </td>
                    <td>
                      Margin for Sell: <strong>{{ auction.margin_for_sell_formatted }}</strong><br>
                      If Settlement Failed: <br>
                      Seller Penalty: <strong>{{ auction.seller_penalty_formatted }}</strong><br>
                      Buyer Compensation: <strong>{{ auction.buyer_compensation_formatted }}</strong><br>
                    </td>
                  </tr>
                  <tr>
                    <td class="pl-0">Repeat</td>
                    <td>
                      <div v-if="auction.repeat_type == ''">
                        -
                      </div>
                      <div v-else>
                        Repeat Type: <strong class="text-capitalize">{{ auction.repeat_type }}</strong> <br>
                        <span v-if="auction.repeat_type != 'daily'">
                          Repeat At: {{ auction.repeat_at }}<br>
                        </span>
                        Skip Weekend &amp; Holiday: {{ auction.repeat_skip_weekend_holiday ? 'Yes' : 'No' }}<br>
                      </div>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
          <button class="btn btn-info mt-1 float-right btn-block" v-if="checkPermission('export auction detail')" @click="exportAuction()">
            <feather-icon
              icon="DownloadIcon"
              class="mr-75"
            />
            Export All Data to Excel
          </button>
        </b-card-code>
      </div>
      <div class="col-md-4">

        <b-card-code title="Time">
          Date: <strong>{{ auction.date }}</strong>
          <br>
          <br>
          <ul class="timeline">
            <li class="timeline-item" v-for="(time, key) in auction.times" :key="time.id">
                <span class="timeline-point timeline-point-indicator"></span>
                <div class="timeline-event">
                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                        <h6>{{ ordinalSuffix(key + 1) }} session</h6>
                    </div>
                    <p>
                      Auction Time: <strong>{{ time.start_at_time_only }} - {{ time.end_at_time_only }}</strong><br>
                      Break: <strong>{{ time.break_at_time_only }} - {{ time.break_finish_at_time_only }}</strong><br>
                    </p>
                </div>
            </li>
          </ul>
        </b-card-code>

        <b-card-code no-body title="Table Lot" style="max-height: 800px; overflow-y: scroll;">
          <div class="table-responsive-sm" v-if="typeof tableLot.bid_side == 'undefined'">
            <table class="table text-center">
              <thead>
                <tr>
                  <th>Bid Lot</th>
                  <th>Price</th>
                  <th>Ask Lot</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="item in tableLot">
                  <tr v-bind:key="item.price" v-if="item.bid_lot > 0 || item.ask_lot">
                    <td v-bind:id="'bidprice_' + item.price">{{ item.bid_lot }}</td>
                    <td>{{ item.price }}</td>
                    <td v-bind:id="'askprice_' + item.price">{{ item.ask_lot }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="table-responsive-sm" v-else>
            <div class="row text-center">
              <div class="col-6 pr-0">
                <table class="table table-hover table-sm">
                  <thead>
                    <tr>
                      <th>Bid Lot</th>
                      <th>Bid Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in tableLot.bid_side" v-bind:key="item.price">
                      <td v-bind:id="'price_' + item.price">{{ item.bid_lot }}</td>
                      <td>{{ item.price }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-6 pl-0">
                <table class="table table-hover table-sm">
                  <thead>
                    <tr>
                      <th>Ask Price</th>
                      <th>Ask Lot</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in tableLot.ask_side" v-bind:key="item.price">
                      <td>{{ item.price }}</td>
                      <td v-bind:id="'price_' + item.price">{{ item.ask_lot }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </b-card-code>

      </div>

    </div>

    <b-card-code no-body title="Bid/Ask List" v-if="checkPermission('show auction transactions')">
      <div class="table-responsive-sm">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>ID</th>
              <th>User</th>
              <th>Type</th>
              <th>Order Price</th>
              <th>Match Price</th>
              <th>Time</th>
              <th>Status</th>
              <th>Result</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(transaction, index) in transactions.data" v-bind:key="index">
              <td>{{ transaction.id }}</td>
              <td v-if="transaction.user != null">
                <router-link :to="{ name: 'users.show', params: { id: transaction.user.id } }">
                    {{ transaction.user.code }}
                </router-link>
              </td><td v-else>-</td>
              <td class="text-capitalize">{{ transaction.type }}</td>
              <td>{{ transaction.price }}</td>
              <td>{{ transaction.lot_complete > 0 ? transaction.fixed_price : '-' }}</td>
              <td>{{ transaction.time }}</td>
              <td class="text-capitalize">{{ transaction.status }}</td>
              <td v-bind:class="transaction.lot_open == 0 ? 'text-success' : (transaction.lot_complete > 0 ? 'text-info' : 'text-danger')">
                <div v-if="transaction.status != 'canceled'">
                  <span class="text-capitalize">{{ transaction.type }}</span>: {{ transaction.lot }} lot<br>
                  Complete: {{ transaction.lot_complete }} lot<br>
                  {{ transaction.status == 'pending' ? 'Open' : 'Unmatched' }}: {{ transaction.lot_open }} lot<br>
                </div>
                <div v-else>-</div>
              </td>
              <td>
                <button class="btn btn-success" v-if="checkPermission('show auction transaction detail')" @click="goToDetailTransaction(transaction.uuid)">Detail</button>
              </td>
            </tr>
            <tr v-if="transactions.total == 0">
              <td colspan="9" class="text-center">Data is empty.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="m-1" v-if="transactions.total > 0">
        <div class="row">
          <div class="col">
            <small>Showing {{ transactions.from }} to {{ transactions.to }} from {{ transactions.total }}</small>
          </div>
          <div class="col">
            <pagination :data="transactions" @pagination-change-page="loadTransactions" :limit="4" align="right"></pagination>
          </div>
        </div>
      </div>
    </b-card-code>

    <b-card-code no-body title="Matched List">
          <div class="table-responsive-sm">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>User</th>
                  <th> Match Price</th>
                  <th>Lot</th>
                  <th>Qty</th>
                  <th>Time</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(matched, index) in matcheds.data" v-bind:key="index">
                  <td>{{ matched.id }}</td>
                  <td>
                    Buyer: <router-link :to="{ name: 'users.show', params: { id: matched.bid_transaction.user.id } }"><strong>{{ matched.bid_transaction.user.code }}</strong></router-link> (Bid Price: {{ matched.bid_transaction.price }})<br>
                    Seller: <router-link :to="{ name: 'users.show', params: { id: matched.ask_transaction.user.id } }"><strong>{{ matched.ask_transaction.user.code }}</strong></router-link> (Ask Price: {{ matched.ask_transaction.price }})
                  </td>
                  <td>{{ matched.price }}</td>
                  <td>{{ matched.lot }} Lot</td>
                  <td>{{ matched.qty }}</td>
                  <td>{{ matched.time }}</td>
                  <td class="text-capitalize">{{ matched.status }}</td>
                </tr>
                <tr v-if="matcheds.total == 0">
                  <td colspan="7" class="text-center">Data is empty.</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="m-1" v-if="matcheds.total > 0">
            <div class="row">
              <div class="col">
                <small>Showing {{ matcheds.from }} to {{ matcheds.to }} from {{ matcheds.total }}</small>
              </div>
              <div class="col">
                <pagination :data="matcheds" @pagination-change-page="loadMatcheds" :limit="4" align="right"></pagination>
              </div>
            </div>
          </div>

          <div class="alert alert-info m-1 p-1">
            <strong>Match Status:</strong><br>
            <ul>
              <li><strong>Matched</strong>: Transaction has been matched.</li>
              <li><strong>Success</strong>: Settlement complete, commodity balance successfully transferred to buyer, cash balance has been transferred to Seller.</li>
              <li><strong>Failed</strong>: Settlement failed.</li>
            </ul>
          </div>

        </b-card-code>

  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { checkPermission } from '@/auth/utils'
import FileSaver from 'file-saver'
import moment from 'moment'
import { timestamp } from '@vueuse/shared'

export default {
  title () {
    return `Detail Auction`
  },
  components: {
    BCard,
    BCardText,
    BCardCode,
  },
  data() {
    return {
      uuid: this.$route.params.uuid,
      auction: Object,
      transactions: Object,
      matcheds: Object,
      tableLot: [],
      currentTransactionPage: 1,
      currenctMatchPage: 1,
    }
  },
  watch: {
    tableLot: {
      handler: function(currentTableLot, oldTableLot) {

        var context = this
        if (typeof currentTableLot.bid_side == 'undefined') {

          // Dutch Auction
          if (oldTableLot.length > 0) {

            currentTableLot.forEach(function(currentTableLotItem){
              oldTableLot.forEach(function(oldTableLotItem){
                if (oldTableLotItem.price == currentTableLotItem.price) {
                  if (oldTableLotItem.bid_lot != currentTableLotItem.bid_lot) {
                    context.addAnimationTableLot('bid', currentTableLotItem.price);
                  }
                  if (oldTableLotItem.ask_lot != currentTableLotItem.ask_lot) {
                    context.addAnimationTableLot('ask', currentTableLotItem.price);
                  }
                }
              })
            })

          } 
          
        } else {

          // Running Trade
          if (oldTableLot.bid_side.length > 0) {

            currentTableLot.bid_side.forEach(function(currentTableLotItem){
              var isFound = false;
              oldTableLot.bid_side.forEach(function(oldTableLotItem){
                if (oldTableLotItem.price == currentTableLotItem.price) {
                  if (oldTableLotItem.bid_lot != currentTableLotItem.bid_lot) {
                    context.addAnimationTableLot('', currentTableLotItem.price);
                  }
                  isFound = true;
                }
              })

              if (!isFound) {
                context.addAnimationTableLot('', currentTableLotItem.price);
              }
            })

            currentTableLot.ask_side.forEach(function(currentTableLotItem){
              var isFound = false;
              oldTableLot.ask_side.forEach(function(oldTableLotItem){
                if (oldTableLotItem.price == currentTableLotItem.price) {
                  if (oldTableLotItem.ask_lot != currentTableLotItem.ask_lot) {
                    context.addAnimationTableLot('', currentTableLotItem.price);
                  }
                  isFound = true;
                }
              })

              if (!isFound) {
                context.addAnimationTableLot('', currentTableLotItem.price);
              }
            })

          } 

        }

      }
    }
  },
  async created() {
    this.loadTableLot();
    this.loadAuctionData();
  },
  mounted() {
    this.$echo.channel('AuctionUpdate.' + this.$route.params.uuid).listen('.AuctionUpdate', (payload) => {
      this.tableLot = payload.data.tableLot;
      for (const key in payload.data) {
        this.auction[key] = payload.data[key]
      }

      this.loadMatcheds(this.currenctMatchPage);
      if (checkPermission('show auction transactions')) {
        this.loadTransactions(this.currentTransactionPage);
      }
    });
  },
  methods: {
    addAnimationTableLot(type, price) {
      setTimeout(function() {
        var element = document.getElementById(type + "price_" + price)
        element.classList.add("NewDataFadeIn")

        setTimeout(function() {
          element.classList.remove("NewDataFadeIn")
        }, 2000);
      }, 200);
    },
    exportAuction() {
      this.$http.get('/admin/auction/' + this.uuid + '/export', {
          responseType: 'blob'
      }).then(response => {
        var timestamp = moment().format('YYYY:MM:DD:HH:mm:ss');
        var fileName = this.auction.commodity.name + '-' + this.auction.warehouse.name + '-' + timestamp  + '.xlsx';
        FileSaver.saveAs(response.data, fileName);
      })
    },
    goToDetailTransaction(uuid) {
      this.$router.push({ name: 'auctions.transactions.show', params: { uuid: uuid } })
    },
    loadAuctionData() {
      this.loadAuctionDetail();
      this.loadMatcheds(this.currenctMatchPage);
      if (checkPermission('show auction transactions')) {
        this.loadTransactions(this.currentTransactionPage);
      }
    },
    loadAuctionDetail() {
      this.$http.get('/admin/auction/' + this.uuid).then(response => {
        this.auction = response.data.data;
      })
    },
    loadTableLot() {
      this.$http.get('/public/commodities/auctions/' + this.uuid + '/table-lot').then(response => {
        this.tableLot = response.data.data;
      })
    },
    loadTransactions(page = 1) {
      this.currentTransactionPage = page;
      this.$http.get('/admin/auction-transactions?auction_uuid=' + this.uuid + '&page=' + page).then(response => {
        this.transactions = response.data.data;
      })
    },
    loadMatcheds(page = 1) {
      this.currenctMatchPage = page;
      this.$http.get('/admin/auction/' + this.uuid + '/matched?page=' + page).then(response => {
        this.matcheds = response.data.data;
      })
    },
    ordinalSuffix(number) {
      var modulusTen = number % 10,
          modulusHundred = number % 100;
      if (modulusTen == 1 && modulusHundred != 11) {
          return number + "st";
      }
      if (modulusTen == 2 && modulusHundred != 12) {
          return number + "nd";
      }
      if (modulusTen == 3 && modulusHundred != 13) {
          return number + "rd";
      }
      return number + "th";
  }
  },
  setup() {
    return {
      checkPermission,
    }
  },
}
</script>

<style>
@keyframes FadeIn {
  from {
    background-color: #eeb886;
  }
  
  to {
    background-color: white;
  }
}

.NewDataFadeIn {
    background-color: white;
    animation: FadeIn 1s ease-in-out forwards;
}
</style>
